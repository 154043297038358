exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-funding-js": () => import("./../../../src/pages/funding.js" /* webpackChunkName: "component---src-pages-funding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leihdtools-js": () => import("./../../../src/pages/leihdtools.js" /* webpackChunkName: "component---src-pages-leihdtools-js" */),
  "component---src-pages-lesrepo-js": () => import("./../../../src/pages/lesrepo.js" /* webpackChunkName: "component---src-pages-lesrepo-js" */),
  "component---src-pages-our-methods-js": () => import("./../../../src/pages/ourMethods.js" /* webpackChunkName: "component---src-pages-our-methods-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-wwd-js": () => import("./../../../src/pages/wwd.js" /* webpackChunkName: "component---src-pages-wwd-js" */)
}

